import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga'

import './index.css'
import App from './App'

ReactGA.initialize('UA-135682867-13')
ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)
