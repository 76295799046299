import styled from 'styled-components'
import DarkModeToggle from 'react-dark-mode-toggle'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${(props) => (props.isDark ? '#1b1f22' : '#eee')};
  height: 100%;
  position: fixed;
  max-height: 100vh;
  left: 0;
  top: 0;
  z-index: 98;
  width: 230px;
  transition: all 0.3s ease-in-out;

  @media (max-width: 1150px) {
    display: none;
  }
`

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 2em;
`

const Logo = styled.h1`
  font-weight: 900;
  margin-left: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  font-size: 1.5em;
`

const Link = styled.a`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 55px;
  padding-left: 2em;
  line-height: 55px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  border-left: 5px rgba(255, 255, 255, 0) solid;

  &:nth-child(2) {
    border-left: 5px #8c38d5 solid;
    color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
    background: ${(props) => (props.isDark ? '#23272a' : '#fff')};
  }

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    color: #686868;
    transition: color 0.3s ease;

    &:hover {
      color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
    }
  }
`

const Footer = styled.div`
  text-align: center;
  margin-bottom: 2em;
`
const Copyright = styled.p`
  font-weight: 300;
  font-size: 0.8em;
  transition: all 0.3s ease-in-out;
  color: #686868;
`

const SideNav = ({ isDark, setIsDark, openPopup }) => (
  <Wrapper isDark={isDark}>
    <Menu>
      <Logo isDark={isDark}>SNOWYCODE</Logo>
      <Link isDark={isDark} href='#'>
        Code Directory
      </Link>
      <Link isDark={isDark} href='#' onClick={() => openPopup()}>
        Add Snippet
      </Link>
      <Link isDark={isDark} href='https://blog.snowycode.com'>
        Blog
      </Link>
      <Link isDark={isDark} href='mailto:servicenowresource@gmail.com'>
        Contact Us
      </Link>
    </Menu>
    <Footer>
      <DarkModeToggle
        onChange={() => setIsDark((prev) => !prev)}
        checked={isDark}
        size={60}
      />
      <Copyright isDark={isDark}>
        © Snowycode {new Date().getFullYear()}. All rights reserved.
      </Copyright>
    </Footer>
  </Wrapper>
)

export default SideNav
