import { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { VscClose } from 'react-icons/vsc'

import Notification from './Notification'

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000000a3;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  background: #23272a;
  border-radius: 10px;
  padding: 4em 0 5em 0;
  width: 90vw;
  max-width: 720px;
  text-align: center;
  position: relative;
  max-height: 70vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 1.6em;
  margin-top: 0;
  margin-bottom: 1.5em;
`

const InputName = styled.label`
  font-size: 1.2em;
`

const Input = styled.input`
  display: block;
  width: calc(100% - 2em);
  outline: none;
  border: none;
  background: #1b1f22;
  border-radius: 10px;
  color: #fff;
  padding: 1em;
  font-size: 1em;
  margin-top: 0.5em;
`

const TextArea = styled.textarea`
  display: block;
  width: calc(100% - 2em);
  outline: none;
  border: none;
  background: #1b1f22;
  border-radius: 10px;
  color: #fff;
  padding: 1em;
  font-size: 1em;
  margin-top: 0.5em;
  resize: none;
`

const SubmitButton = styled.button`
  padding: 0.8em 2.3em;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  color: #fff;
  background: linear-gradient(90deg, #5400a3, #2a0052);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const Close = styled(VscClose)`
  position: absolute;
  font-size: 2em;
  right: 0.7em;
  top: 0.7em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const InputWrapper = styled.div`
  text-align: left;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1.5em;
`

const ApplyForJob = ({ closePopup, notifications }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [duration, setDuration] = useState('')
  const [serviceNowExperience, setServiceNowExperience] = useState(``)
  const [contentExperience, setContentExperience] = useState('')
  const [showNotification, setShowNotification] = useState(false)
  const [message, setMessage] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(null)

  const close = () => {
    if (notifications) {
      setTimeout(
        () =>
          closePopup() &&
          setShowNotification(false) &&
          setMessage('') &&
          setIsSuccessful(null),
        5000
      )
    } else {
      closePopup()
    }
  }

  const submit = async (e) => {
    e.preventDefault()

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/utils/applyForJob`, {
        name,
        email,
        duration,
        serviceNowExperience,
        contentExperience,
      })

      if (notifications) {
        setShowNotification(true)
        setMessage(
          'Thank you for interest! We will review the submission and get back to you.'
        )
        setIsSuccessful(true)
      }

      close()
    } catch (error) {
      if (notifications) {
        setShowNotification(false)
        setMessage('Something went wrong! Please try again later...')
        setIsSuccessful(false)
      }

      close()
    }
  }

  return (
    <Popup>
      {showNotification && (
        <Notification message={message} isSuccessful={isSuccessful} />
      )}
      <Form onSubmit={submit}>
        <Close onClick={closePopup} />
        <Title>Become A Content Creator</Title>
        <InputWrapper>
          <InputName htmlFor='name'>Name *</InputName>
          <Input
            placeholder='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='email'>Email *</InputName>
          <Input
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='duration'>
            How long have you worked with ServiceNow *
          </InputName>
          <Input
            placeholder='Duration'
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='experience'>
            Please describe your experience with ServiceNow *
          </InputName>
          <TextArea
            placeholder='Experience with ServiceNow'
            value={serviceNowExperience}
            onChange={(e) => setServiceNowExperience(e.target.value)}
            rows='3'
            required
          ></TextArea>
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='content'>
            Do you have experience with content creation *
          </InputName>
          <TextArea
            placeholder='Experience with content creation'
            value={contentExperience}
            onChange={(e) => setContentExperience(e.target.value)}
            rows='3'
            required
          ></TextArea>
        </InputWrapper>
        <SubmitButton>Apply</SubmitButton>
      </Form>
    </Popup>
  )
}

export default ApplyForJob
