import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { gradientDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const WrapperParent = styled.div`
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
  }
`

const WrapperInner = styled.div`
  background: #1b1f22;
  padding: 1em;
  border-radius: 10px;
  position: relative;
  margin: 1em;
  cursor: pointer;
  height: 350px;
  display: flex;
  flex-direction: column;

  @media (max-width: 620px) {
    margin: 1em 0;
    height: 500px;
  }
`

const Title = styled.h4`
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
  transition: all 0.3s ease-in-out;
  color: #fff;
`

const Description = styled.p`
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  font-size: 1.1em;
  color: #d2d2d2;
`

const Buttons = styled.div`
  padding: 0 1em;
  margin-bottom: 1em;
`

const Button = styled.button`
  padding: 0.4em 1.2em;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  color: #fff;
  background: ${(props) => props.background};
  cursor: pointer;
  margin-right: 1em;

  &:hover {
    opacity: 0.8;
  }

  &:last-child {
    margin-right: 0;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;

  @media (max-width: 885px) {
    flex-direction: column;
  }
`

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 885px) {
    align-items: center;
    justify-content: center;
  }
`

const Tag = styled.span`
  display: flex;
  align-items: center;
  background: #1b1f22;
  border-radius: 10px;
  padding: 0em 1.2em;
  height: 2.2em;
  margin: 0.4em;
  font-size: 0.95em;
  cursor: pointer;
  color: #fff;
  border: 1px solid #424d54;
`

const AdminCard = ({
  id,
  title,
  description,
  code,
  tags,
  approveSnippet,
  deleteSnippet,
  openEditPopup,
}) => {
  return (
    <WrapperParent>
      <WrapperInner>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <SyntaxHighlighter
          language='javascript'
          style={gradientDark}
          customStyle={{
            background: 'inherit',
            overflowX: 'auto',
            height: '100%',
            marginBottom: '0',
          }}
          wrapLongLines={false}
          showLineNumbers={true}
        >
          {code}
        </SyntaxHighlighter>
        {tags.length > 0 && (
          <Footer>
            <Tags>
              {tags.map((tag) => (
                <Tag>{tag}</Tag>
              ))}
            </Tags>
          </Footer>
        )}
      </WrapperInner>
      <Buttons>
        {approveSnippet !== null && (
          <Button onClick={() => approveSnippet(id)} background={'#4BB543'}>
            Approve
          </Button>
        )}
        <Button onClick={() => deleteSnippet(id)} background={'#ff0033'}>
          Delete
        </Button>
        <Button onClick={() => openEditPopup(id)} background={'#404040'}>
          Edit
        </Button>
      </Buttons>
    </WrapperParent>
  )
}

export default AdminCard
