import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  border-radius: 10px;
  background: ${(props) => (props.isSuccessful ? '#4BB543' : '#ff0033')};
  top: 1em;
  padding: 1em 2em;
  z-index: 999;
  margin: 0 1em;
`

const Text = styled.p`
  font-size: 1.1em;
  font-weight: 600;
`

const Notification = ({ message, isSuccessful }) => (
  <Wrapper isSuccessful={isSuccessful}>
    <Text>{message}</Text>
  </Wrapper>
)

export default Notification
