import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  border-radius: 10px;
  background: #1b1f22;
  top: 1em;
  padding: 1em 2em;
  z-index: 999;
  margin: 0 1em;
  border: 1px solid #8c38d5;
`

const Text = styled.p`
  font-size: 1.1em;
`

const CopiedNotification = ({ type }) => (
  <Wrapper>
    <Text>
      {type === 'snippet'
        ? 'Code snippet copied to clipboard'
        : 'Shareable link copied to clipboard'}
    </Text>
  </Wrapper>
)

export default CopiedNotification
