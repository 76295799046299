import { useState } from 'react'
import styled from 'styled-components'
import { VscClose, VscMenu } from 'react-icons/vsc'
import DarkModeToggle from 'react-dark-mode-toggle'

const Wrapper = styled.nav`
  display: none;

  @media (max-width: 1150px) {
    display: flex;
    padding: 1em 1em;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`

const MenuButton = styled(VscMenu)`
  font-size: 2em;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#ffffff' : '#1b1f22')};
  top: -2px;
  cursor: pointer;
  margin-right: 1.5em;
`

const CloseButton = styled(VscClose)`
  font-size: 2.7em;
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#ffffff' : '#1b1f22')};
`

const FullScreenMenu = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  background: ${(props) => (props.isDark ? '#23272a' : '#fff')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.fullScreenMenuOpened ? 'flex' : 'none')};
`

const FullScreenNavLink = styled.a`
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#ffffff' : '#1b1f22')};
  font-size: 2.5em;
  cursor: pointer;
`

const Logo = styled.h1`
  font-weight: 900;
  margin-left: 2em;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#ffffff' : '#1b1f22')};
  cursor: pointer;
`

const ToggleHolder = styled.div``

const Nav = ({ isDark, setIsDark, openPopup }) => {
  const [fullScreenMenuOpened, toggleFullScreenMenu] = useState(false)

  return (
    <Wrapper>
      <Logo isDark={isDark}>SNOWYCODE</Logo>
      <MenuButton
        isDark={isDark}
        onClick={() => toggleFullScreenMenu(!fullScreenMenuOpened)}
      />

      <FullScreenMenu
        isDark={isDark}
        fullScreenMenuOpened={fullScreenMenuOpened}
      >
        <CloseButton
          isDark={isDark}
          onClick={() => toggleFullScreenMenu(!fullScreenMenuOpened)}
        />
        <ToggleHolder>
          <DarkModeToggle
            onChange={() => setIsDark((prev) => !prev)}
            checked={isDark}
            size={90}
          />
        </ToggleHolder>

        <FullScreenNavLink
          isDark={isDark}
          onClick={() => toggleFullScreenMenu(!fullScreenMenuOpened)}
        >
          Code Directory
        </FullScreenNavLink>
        <FullScreenNavLink
          isDark={isDark}
          onClick={() =>
            openPopup() && toggleFullScreenMenu(!fullScreenMenuOpened)
          }
        >
          Add Snippet
        </FullScreenNavLink>
        <FullScreenNavLink
          isDark={isDark}
          href='mailto:servicenowresource@gmail.com'
        >
          Contact Us
        </FullScreenNavLink>
      </FullScreenMenu>
    </Wrapper>
  )
}

export default Nav
