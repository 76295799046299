import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Loading from 'react-fullscreen-loading'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import { TiTick } from 'react-icons/ti'

import Card from './components/Card'
import Nav from './components/Nav'
import SideNav from './components/SideNav'
import AddSnippet from './components/popups/AddSnippet'
import AdminDashboard from './components/AdminDashboard'
import CopiedNotification from './components/popups/CopiedNotification'
import ApplyForJob from './components/popups/ApplyForJob'

import rocket from './assets/rocket.jpg'
import space from './assets/space.jpg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${(props) => (props.isDark ? '#23272a' : '#fff')};
  transition: all 0.3s ease-in-out;
  min-height: 100vh;
  padding: 0 1em;
  @media (min-width: 1150px) {
    margin-left: ${(props) => (props.isAdmin ? '0' : '230px')};
  }
`

const HeaderWrapper = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;

  @media (max-width: 1150px) {
    display: block;
  }
`

const Header = styled.div`
  background: url(${rocket});
  background-position: center center;
  background-size: cover;
  color: #fff;
  border-radius: 10px;
  margin: 3em 0;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2em;
  flex: 2;

  &:last-child {
    background: url(${space});
    background-position: 51% 30%;
    margin-right: 0;
    flex: 1;
  }

  @media (max-width: 1150px) {
    margin-top: 0;
    margin-right: 0;

    &:first-child {
      min-height: 66vh;
    }

    &:last-child {
      background-position: 54% 30%;
    }
  }
`

const HeaderText = styled.div`
  max-width: 550px;
  margin: 4em;
  text-align: ${(props) => (props.centered ? 'center' : 'left')};

  @media (max-width: 1150px) {
    text-align: left;
  }

  @media (max-width: 885px) {
    margin: 2em;
  }
`

const HeaderTitle = styled.h3`
  font-weight: 600;
  font-size: 1.6em;
  margin-bottom: 0.5em;
`

const HeaderDescription = styled.p`
  font-weight: 300;
  font-size: 1.1em;
  text-align: left;
  margin-bottom: ${(props) => (props.marginButton ? '1em' : '0')};
`

const Text = styled.div`
  width: 90%;
  max-width: 1440px;

  @media (max-width: 885px) {
    text-align: center;
  }
`

const Title = styled.h2`
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  font-size: 1.8em;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  margin-bottom: 0.5em;
`

const Description = styled.p`
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  font-size: 1.1em;
  color: ${(props) => (props.isDark ? '#d2d2d2' : '#202020')};
`

const Search = styled.input`
  margin-top: 2em;
  margin-bottom: 1.5em;
  width: calc(90% - 2em);
  max-width: 1440px;
  outline: none;
  border: 1px solid #8c38d5;
  background: ${(props) => (props.isDark ? '#1b1f22' : '#eee')};
  border-radius: 10px;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  padding: 1em;
  font-size: 1em;
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1440px;
  margin-bottom: 2em;
`

const Button = styled.button`
  padding: 0.8em 2.3em;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  color: #fff;
  background: linear-gradient(90deg, #5400a3, #2a0052);
  cursor: pointer;
  margin-top: 1em;

  &:hover {
    background: linear-gradient(90deg, #2a0052, #5400a3);
  }
`

const Filters = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.8em 0;
  margin-top: 0.4em;
  @media (max-width: 885px) {
    align-items: center;
    justify-content: center;
  }
`

const Icon = styled.span`
  font-size: 1.2em;
  position: relative;
  top: 2px;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #8c38d5;
`

const Filter = styled.span`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: ${(props) => (props.isDark ? '#1b1f22' : '#eee')};
  border-radius: 10px;
  padding: 0em 1.2em;
  height: 2.2em;
  margin: 0.4em;
  font-size: 0.95em;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? '#8c38d5' : props.isDark ? '#fff' : '#1b1f22'};
  border: ${(props) =>
    props.isSelected
      ? '1px solid #8c38d5'
      : `1px solid ${props.isDark ? '#424d54' : '#c2c2c2'}`};
`

const FiltersTitle = styled.p`
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  font-size: 1.1em;
  margin-right: 0.4em;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
`

const App = () => {
  const history = useHistory()
  const location = useLocation()

  const [snippets, setSnippets] = useState([])
  const [isDark, setIsDark] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [filters, setFilters] = useState([])
  const [search, setSearch] = useState('')
  const [popupOpened, setPopupOpened] = useState(false)
  const [jobPopupOpened, setJobPopupOpened] = useState(false)
  const [copiedNotificationType, setCopiedNotificationType] = useState('')

  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/snippets`
      )

      const params = new URLSearchParams(window.location.search)
      const id = params.get('id')

      if (id) setSearch(`id:${id}`)
      setSnippets(data.snippets)
      setIsLoaded(true)
    }

    init()
  }, [])

  useEffect(() => {
    let filtersHelper = []

    if (location.search && location.search.split('=')[1] !== '') {
      const filtersRaw = location.search.split('=')[1].split(',')

      filtersRaw.forEach((filter) =>
        filtersHelper.push(decodeURIComponent(filter))
      )
    }

    setFilters(filtersHelper)
  }, [location.search])

  const addToHistory = (query) => {
    let filtersHelper = []

    if (location.search && location.search.split('=')[1] !== '') {
      const filtersRaw = location.search.split('=')[1].split(',')

      filtersRaw.forEach((filter) =>
        filtersHelper.push(decodeURIComponent(filter))
      )
    }

    if (!filtersHelper.includes(query)) {
      filtersHelper.push(query)
    } else {
      filtersHelper = filtersHelper.filter((x) => x !== query)
    }

    history.push({
      search:
        filtersHelper.length > 0 ? `filters=${filtersHelper.join(',')}` : '',
    })
  }

  const openPopup = () => {
    setPopupOpened(true)
  }

  const closePopup = () => {
    setPopupOpened(false)
  }

  const openJobPopup = () => {
    setJobPopupOpened(true)
  }

  const closeJobPopup = () => {
    setJobPopupOpened(false)
  }

  const showCopiedNotification = (type) => {
    setCopiedNotificationType(type)
    setTimeout(() => setCopiedNotificationType(''), 3500)
  }

  return (
    <Wrapper
      isAdmin={localStorage.getItem('token') ? true : false}
      isDark={isDark}
    >
      <Loading loading={!isLoaded} background='#23272a' loaderColor='#8c38d5' />
      {popupOpened && (
        <AddSnippet closePopup={closePopup} notifications={true} />
      )}
      {jobPopupOpened && (
        <ApplyForJob closePopup={closeJobPopup} notifications={true} />
      )}
      {copiedNotificationType && (
        <CopiedNotification type={copiedNotificationType} />
      )}
      {!localStorage.getItem('token') ? (
        <>
          <Nav openPopup={openPopup} isDark={isDark} setIsDark={setIsDark} />
          <SideNav
            openPopup={openPopup}
            isDark={isDark}
            setIsDark={setIsDark}
          />
          <HeaderWrapper>
            <Header>
              <HeaderText>
                <HeaderTitle>Ready-to-use ServiceNow Code</HeaderTitle>
                <HeaderDescription>
                  Snowycode aims to be the ultimate resource for ServiceNow
                  devs. Our first resource is a directory of common code
                  snippets for the code you write every day in ServiceNow.
                </HeaderDescription>
              </HeaderText>
            </Header>
            <Header>
              <HeaderText centered='true'>
                <HeaderTitle>Become A Content Creator</HeaderTitle>
                <HeaderDescription marginButton='true'>
                  We are looking for someone talented to create content for
                  Snowycode (paid position!)
                </HeaderDescription>
                <HeaderDescription>
                  If you think you'd be good at writing tutorials, adding
                  quality scripts, and writing how-to guides for things inside
                  of ServiceNow, then click below to get in touch.
                </HeaderDescription>
                <Button onClick={() => openJobPopup()}>Apply Now</Button>
              </HeaderText>
            </Header>
          </HeaderWrapper>

          <Text>
            <Title isDark={isDark}>ServiceNow Code Directory</Title>
            <Description isDark={isDark}>
              Click on any code below to copy it to your clipboard.
            </Description>
          </Text>

          <Search
            isDark={isDark}
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Filters>
            <FiltersTitle isDark={isDark}>Filter by tag</FiltersTitle>

            <Filter
              isDark={isDark}
              isSelected={filters.includes('Client Script')}
              onClick={() => addToHistory('Client Script')}
            >
              {filters.includes('Client Script') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Client Script
            </Filter>
            <Filter
              isDark={isDark}
              isSelected={filters.includes('Business Rule')}
              onClick={() => addToHistory('Business Rule')}
            >
              {filters.includes('Business Rule') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Business Rule
            </Filter>
            <Filter
              isDark={isDark}
              isSelected={filters.includes('Script Include')}
              onClick={() => addToHistory('Script Include')}
            >
              {filters.includes('Script Include') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Script Include
            </Filter>
            <Filter
              isDark={isDark}
              isSelected={filters.includes('Background/Fix Script')}
              onClick={() => addToHistory('Background/Fix Script')}
            >
              {filters.includes('Background/Fix Script') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Background/Fix Script
            </Filter>
          </Filters>

          <Cards>
            {snippets
              .filter((snippet) => {
                if (search.includes('id:')) {
                  return snippet._id === search.replace('id:', '')
                }

                return snippet.title
                  .toLowerCase()
                  .includes(search.toLowerCase().trim())
              })
              .filter((snippet) => {
                if (filters.length > 0) {
                  return filters.some((x) => snippet.tags.includes(x))
                }

                return snippet._id !== -1
              })
              .map((snippet) => (
                <Card
                  key={snippet._id}
                  id={snippet._id}
                  isDark={isDark}
                  title={snippet.title}
                  description={snippet.description}
                  linkedInUrl={snippet.linkedInUrl}
                  tags={snippet.tags}
                  code={snippet.code}
                  showCopiedNotification={showCopiedNotification}
                />
              ))}
          </Cards>
        </>
      ) : (
        <AdminDashboard />
      )}
    </Wrapper>
  )
}

export default App
