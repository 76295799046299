import { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { VscClose } from 'react-icons/vsc'
import CodeEditor from '@uiw/react-textarea-code-editor'
import Loading from 'react-fullscreen-loading'
import { TiTick } from 'react-icons/ti'

import Notification from './Notification'

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #000000a3;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  background: #23272a;
  border-radius: 10px;
  padding: 4em 0 5em 0;
  width: 90vw;
  max-width: 720px;
  text-align: center;
  position: relative;
  max-height: 70vh;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Title = styled.h3`
  font-weight: 600;
  font-size: 1.6em;
  margin-bottom: 1.5em;
  margin-top: 0;
`

const InputName = styled.label`
  font-size: 1.2em;
`

const Input = styled.input`
  display: block;
  width: calc(100% - 2em);
  outline: none;
  border: none;
  background: #1b1f22;
  border-radius: 10px;
  color: #fff;
  padding: 1em;
  font-size: 1em;
  margin-top: 0.5em;
`

const TextArea = styled.textarea`
  display: block;
  width: calc(100% - 2em);
  outline: none;
  border: none;
  background: #1b1f22;
  border-radius: 10px;
  color: #fff;
  padding: 1em;
  font-size: 1em;
  margin-top: 0.5em;
  resize: none;
`

const SubmitButton = styled.button`
  padding: 0.8em 2.3em;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  color: #fff;
  background: linear-gradient(90deg, #5400a3, #2a0052);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const Close = styled(VscClose)`
  position: absolute;
  font-size: 2em;
  right: 0.7em;
  top: 0.7em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const InputWrapper = styled.div`
  text-align: left;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 1.5em;
`

const Filters = styled.div`
  margin-top: 0.5em;
  max-width: 1440px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 885px) {
    align-items: center;
    justify-content: center;
  }
`

const Icon = styled.span`
  font-size: 1.2em;
  position: relative;
  top: 2px;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #8c38d5;
`

const Filter = styled.span`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: #1b1f22;
  border-radius: 10px;
  padding: 0em 1.2em;
  height: 2.2em;
  margin: 0.4em;
  font-size: 0.95em;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? '#8c38d5' : '#fff')};
  border: ${(props) =>
    props.isSelected ? '1px solid #8c38d5' : '1px solid #1b1f22'};
`

const EditSnippet = ({ closeEditPopup, editSnippetId, notifications }) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [code, setCode] = useState(``)
  const [linkedInUrl, setLinkedInUrl] = useState('')
  const [tags, setTags] = useState([])
  const [showNotification, setShowNotification] = useState(false)
  const [message, setMessage] = useState('')
  const [isSuccessful, setIsSuccessful] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/snippets/${editSnippetId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      setTitle(data.snippet.title)
      setDescription(data.snippet.description)
      setLinkedInUrl(data.snippet.linkedInUrl)
      setCode(data.snippet.code)
      setTags(data.snippet.tags)
      setIsLoaded(true)
    }

    init()
  }, [])

  const close = (success) => {
    if (notifications) {
      setTimeout(
        () =>
          closeEditPopup(success ? editSnippetId : null) &&
          setShowNotification(false) &&
          setMessage('') &&
          setIsSuccessful(null),
        5000
      )
    } else {
      closeEditPopup(success ? editSnippetId : null)
    }
  }

  const submit = async (e) => {
    e.preventDefault()

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/snippets/${editSnippetId}`,
        {
          title,
          description,
          code,
          linkedInUrl,
          tags,
        },
        localStorage.getItem('token')
          ? {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          : {}
      )

      if (notifications) {
        setShowNotification(true)
        setMessage(
          'Thank you for your submission and supporting Snowycode! We will review the code and add it to the site if helpful.'
        )
        setIsSuccessful(true)
      }

      close(true)
    } catch (error) {
      if (notifications) {
        setShowNotification(false)
        setMessage('Something went wrong! Please try again later...')
        setIsSuccessful(false)
      }

      close(false)
    }
  }

  return (
    <Popup>
      <Loading loading={!isLoaded} background='#23272a' loaderColor='#8c38d5' />
      {showNotification && (
        <Notification message={message} isSuccessful={isSuccessful} />
      )}
      <Form onSubmit={submit}>
        <Close onClick={closeEditPopup} />
        <Title>Edit Snippet</Title>
        <InputWrapper>
          <InputName htmlFor='title'>Title *</InputName>
          <Input
            placeholder='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='description'>Description *</InputName>
          <TextArea
            placeholder='Description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows='3'
            required
          ></TextArea>
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='linkedInUrl'>
            Your LinkedIn Profile (optional)
          </InputName>
          <Input
            placeholder='LinkedIn Profile URL'
            value={linkedInUrl}
            onChange={(e) => setLinkedInUrl(e.target.value)}
            pattern='https://linkedin\.com\/.*|https://www.linkedin\.com\/.*'
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='code'>Code *</InputName>
          <CodeEditor
            required
            name='code'
            value={code}
            language='js'
            placeholder='...'
            onChange={(e) => setCode(`${e.target.value}`)}
            padding={15}
            style={{
              fontSize: 15,
              borderRadius: 10,
              minHeight: 150,
              marginTop: '0.5em',
              overflowY: 'auto',
              backgroundColor: '#1b1f22',
              fontFamily: 'Montserrat',
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <InputName htmlFor='tags'>Tags (optional)</InputName>
          <Filters>
            <Filter
              isSelected={tags.includes('Client Script')}
              onClick={() =>
                tags.includes('Client Script')
                  ? setTags((prev) =>
                      prev.filter((tag) => tag !== 'Client Script')
                    )
                  : setTags((prev) => [...prev, 'Client Script'])
              }
            >
              {tags.includes('Client Script') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Client Script
            </Filter>
            <Filter
              isSelected={tags.includes('Business Rule')}
              onClick={() =>
                tags.includes('Business Rule')
                  ? setTags((prev) =>
                      prev.filter((tag) => tag !== 'Business Rule')
                    )
                  : setTags((prev) => [...prev, 'Business Rule'])
              }
            >
              {tags.includes('Business Rule') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Business Rule
            </Filter>
            <Filter
              isSelected={tags.includes('Script Include')}
              onClick={() =>
                tags.includes('Script Include')
                  ? setTags((prev) =>
                      prev.filter((tag) => tag !== 'Script Include')
                    )
                  : setTags((prev) => [...prev, 'Script Include'])
              }
            >
              {tags.includes('Script Include') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Script Include
            </Filter>
            <Filter
              isSelected={tags.includes('Background/Fix Script')}
              onClick={() =>
                tags.includes('Background/Fix Script')
                  ? setTags((prev) =>
                      prev.filter((tag) => tag !== 'Background/Fix Script')
                    )
                  : setTags((prev) => [...prev, 'Background/Fix Script'])
              }
            >
              {tags.includes('Background/Fix Script') && (
                <Icon>
                  <TiTick />
                </Icon>
              )}
              Background/Fix Script
            </Filter>
          </Filters>
        </InputWrapper>
        <SubmitButton>Approve</SubmitButton>
      </Form>
    </Popup>
  )
}

export default EditSnippet
