import { useState } from 'react'
import styled from 'styled-components'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { vs, gradientDark } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { ImLinkedin, ImShare2 } from 'react-icons/im'

const WrapperParent = styled.div`
  width: 50%;
  position: relative;
  transition: all 0.3s ease-in-out;
  animation: ${(props) => (props.shouldPulse ? 'pulse .4s' : 'none')}}

  @media (max-width: 1200px) {
    width: 100%;
  }
`

const WrapperInner = styled.div`
  background: ${(props) => (props.isDark ? '#1b1f22' : '#eee')};
  padding: 1em;
  border-radius: 10px;
  position: relative;
  margin: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 350px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 620px) {
    margin: 1em 0;
    height: 500px;
  }

  &:hover {
    box-shadow: 0px 0px 15px #8c38d5;
  }
`

const Title = styled.h4`
  font-size: 1.3em;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-right: 2em;
  margin-top: 0;
  transition: all 0.3s ease-in-out;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
`

const Description = styled.p`
  font-weight: 300;
  transition: all 0.3s ease-in-out;
  font-size: 1.1em;
  color: ${(props) => (props.isDark ? '#d2d2d2' : '#202020')};
`

const LinkedIn = styled.div`
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};

  @media (max-width: 885px) {
    margin-top: 0.5em;
  }
`

const LinkedInIcon = styled(ImLinkedin)`
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  height: 25px;
  width: 25px;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 0.5em;
  transition: 0.3s ease-in-out;

  &:hover {
    color: #8c38d5;
  }
`

const ShareIcon = styled(ImShare2)`
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  position: absolute;
  right: 2em;
  top: 2em;
  z-index: 1;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  animation: ${(props) => (props.shouldPulse ? 'pulse-more .4s' : 'none')}}

  &:hover {
    color: #8c38d5;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;

  @media (max-width: 885px) {
    flex-direction: column;
  }
`

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 75%;

  @media (max-width: 885px) {
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }
`

const Tag = styled.span`
  display: flex;
  align-items: center;
  background: ${(props) => (props.isDark ? '#1b1f22' : '#eee')};
  border-radius: 10px;
  padding: 0em 1.2em;
  height: 2.2em;
  margin: 0.4em;
  font-size: 0.95em;
  cursor: pointer;
  color: ${(props) => (props.isDark ? '#fff' : '#1b1f22')};
  border: ${(props) => `1px solid ${props.isDark ? '#424d54' : '#c2c2c2'}`};
`

const Card = ({
  id,
  title,
  description,
  code,
  linkedInUrl,
  tags,
  isDark,
  showCopiedNotification,
}) => {
  const [shouldPulse, setShouldPulse] = useState(false)
  const [shouldSharingLinkPulse, setShouldSharingLinkPulse] = useState(false)

  const copyToClipboard = () => {
    showCopiedNotification('snippet')
    setShouldPulse(true)

    navigator.clipboard.writeText(code)

    setTimeout(() => setShouldPulse(false), 400)
  }

  const copySharingLinkToClipboard = () => {
    showCopiedNotification('link')
    setShouldSharingLinkPulse(true)

    navigator.clipboard.writeText(`https://snowycode.com/?id=${id}`)

    setTimeout(() => setShouldSharingLinkPulse(false), 400)
  }

  return (
    <WrapperParent shouldPulse={shouldPulse}>
      <ShareIcon
        shouldPulse={shouldSharingLinkPulse}
        onClick={copySharingLinkToClipboard}
        isDark={isDark}
      />
      <WrapperInner isDark={isDark} onClick={copyToClipboard}>
        <Title isDark={isDark}>{title}</Title>
        <Description isDark={isDark}>{description}</Description>
        <SyntaxHighlighter
          language='javascript'
          style={isDark ? gradientDark : vs}
          customStyle={{
            background: 'inherit',
            overflowX: 'auto',
            height: '100%',
            minHeight: '150px',
            marginBottom: '0',
          }}
          wrapLongLines={false}
          showLineNumbers={true}
        >
          {code}
        </SyntaxHighlighter>
        <Footer>
          <Tags>
            {tags.map((tag) => (
              <Tag isDark={isDark}>{tag}</Tag>
            ))}
          </Tags>

          <LinkedIn isDark={isDark}>
            {linkedInUrl && (
              <a href={linkedInUrl} target='_blank' rel='noreferrer'>
                Submitted by
                <LinkedInIcon isDark={isDark} />
              </a>
            )}
          </LinkedIn>
        </Footer>
      </WrapperInner>
    </WrapperParent>
  )
}

export default Card
