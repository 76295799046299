import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Loading from 'react-fullscreen-loading'
import axios from 'axios'

import AdminCard from './AdminCard'
import EditSnippet from './popups/EditSnippet'
import AddSnippet from './popups/AddSnippet'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 1em;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  width: 90%;
  max-width: 1440px;
  min-height: 220px;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.h3`
  font-weight: 600;
  font-size: 1.6em;
`

const TextLight = styled.h3`
  font-weight: 400;
  font-size: 1.6em;
`

const Button = styled.button`
  padding: 0.8em 2.3em;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  color: #fff;
  background: linear-gradient(90deg, #5400a3, #2a0052);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1440px;
  margin-bottom: 2em;
`

const AdminDashboard = () => {
  const [snippets, setSnippets] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [editPopupOpen, setEditPopupOpen] = useState(false)
  const [editSnippetId, setEditSnippetId] = useState(null)
  const [popupOpened, setPopupOpened] = useState(false)

  useEffect(() => {
    const init = async () => {
      const adminData = await axios.get(
        `${process.env.REACT_APP_API_URL}/utils/validateAdmin`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      if (!adminData.data.isAdmin) {
        return localStorage.removeItem('token')
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/snippets`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      setSnippets(data.snippets)
      setIsLoaded(true)
    }

    init()
  }, [])

  const approveSnippet = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/snippets/approve/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    const index = snippets.findIndex((snippet) => snippet._id === id)
    const newSnippets = [...snippets]
    newSnippets[index].approved = true
    setSnippets(newSnippets)
  }

  const deleteSnippet = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/snippets/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })

    const newSnippets = snippets.filter((snippet) => snippet._id !== id)

    setSnippets(newSnippets)
  }

  const openEditPopup = (id) => {
    setEditPopupOpen(true)
    setEditSnippetId(id)
  }

  const closeEditPopup = async (id) => {
    if (id !== null) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/snippets/${id}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      )

      const index = snippets.findIndex((snippet) => snippet._id === id)
      const newSnippets = [...snippets]
      newSnippets[index] = data.snippet

      setSnippets(newSnippets)
    }

    setEditPopupOpen(false)
    setEditSnippetId(null)
  }

  const openPopup = () => {
    setPopupOpened(true)
  }

  const closePopup = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/snippets`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }
    )

    setSnippets(data.snippets)
    setPopupOpened(false)
  }

  return (
    <Wrapper>
      <Loading loading={!isLoaded} background='#23272a' loaderColor='#8c38d5' />
      {popupOpened && (
        <AddSnippet closePopup={closePopup} notifications={false} />
      )}
      {editPopupOpen && (
        <EditSnippet
          closeEditPopup={closeEditPopup}
          editSnippetId={editSnippetId}
          notifications={false}
        />
      )}
      <Header>
        <Text>ADMIN</Text>
        <Button onClick={openPopup}>Add Snippet</Button>
      </Header>

      {snippets.length > 0 ? (
        <Cards>
          {snippets
            .sort((x, y) =>
              y.approved === x.approved ? 0 : y.approved ? -1 : 1
            )
            .map((snippet) => (
              <AdminCard
                key={snippet._id}
                id={snippet._id}
                title={snippet.title}
                description={snippet.description}
                code={snippet.code}
                tags={snippet.tags}
                approveSnippet={snippet.approved ? null : approveSnippet}
                deleteSnippet={deleteSnippet}
                openEditPopup={openEditPopup}
              />
            ))}
        </Cards>
      ) : (
        <TextLight>No snippets...</TextLight>
      )}
    </Wrapper>
  )
}

export default AdminDashboard
